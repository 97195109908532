import React from 'react';
import { TreatmentsSubpage } from 'views/treatments-subpage';
import { useMesotherapyContent } from 'views/treatments-subpage/hooks/mesotherapy';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { treatmentsHubs } from 'constants/treatment-hubs';
import { Layout } from 'layouts';

const MesotherapyPage = () => {
  const mesotherapyContent = useMesotherapyContent();

  return (
    <Layout>
      <TreatmentsSubpage
        breadcrumbLinks={treatmentsHubs.mesotherapy.links}
        {...mesotherapyContent}
      />
    </Layout>
  );
};

export default MesotherapyPage;

export const Head = () => <SEO tags={TAGS.zabiegiMezoterapia} />;
